export const environment = {
  production: false,
  idleStartTime: 300,
  idleSessionEndTime: 15,
  appInsightsInstrumentationKey: 'e68fc0d6-84d6-4cb3-b86a-ff72b2314411',
  knownAuthorities: ['capitaschoolattendancetool.b2clogin.com'],
  redirectUri: 'https://d100epep.z33.web.core.windows.net/',
  postLogoutRedirectUri: 'https://d100epep.z33.web.core.windows.net/',
  ApiEndpoint: 'https://d001-oneapi-api-uks-apim.azure-api.net/pep/',
  APIMSubscriptionKey: '443c77bc21b64612a03d5ef02a295fa4',

  tenant: 'capitaschoolattendancetool.onmicrosoft.com',
  clientID: '44a9777b-fcdd-47ee-bf31-30611c5953bb',
  scopes: [
    'https://capitaschoolattendancetool.onmicrosoft.com/pepfunctionapp/user_impersonation',
    'offline_access',
    'openid',
    'https://capitaschoolattendancetool.onmicrosoft.com/pepfunctionapp/user_acces'
  ],

  b2cPolicies: {
    names: {
      signIn: 'B2C_1_ePEPSignInFlow',
      resetPassword: 'B2C_1_ePEPResetPasswordFlow'
    },
    authorities: {
      signIn: {
        authority: 'https://capitaschoolattendancetool.b2clogin.com/capitaschoolattendancetool.onmicrosoft.com/B2C_1_ePEPSignInFlow/'
      },
      resetPassword: {
        authority: 'https://capitaschoolattendancetool.b2clogin.com/capitaschoolattendancetool.onmicrosoft.com/B2C_1_ePEPResetPasswordFlow/'
      }
    }
  }
};
